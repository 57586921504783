import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactDOM from "react-dom";
import penerbanganMisiService from "../services/penerbanganMisi.service";
import misiService from "../services/misi.service";
import Slider from "react-slick";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FaWind, FaThermometerHalf, FaTint } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import { FaTrash, FaDownload } from "react-icons/fa";
import {
  FaClock,
  FaRegClock,
  FaHourglassHalf,
  FaUserTie,
  FaUserCog,
  FaRocket,
  FaCogs,
} from "react-icons/fa";

function PenerbanganMisi() {
  const { uuid } = useParams();
  const [flightData, setFlightData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    mulai: "",
    selesai: "",
    wahanaId: "",
    komponenId: [],
    gcsId: "",
    pilotId: "",
    status_misi: false,
    latitude: "",
    longitude: "",
    notice_to_airman: "",
    flight_security_clearense: "",
    izin_lokasi_terbang: "",
    flight_plan: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [pilots, setPilots] = useState([]);
  const [gcsOperators, setGcsOperators] = useState([]);
  const [wahanas, setWahanas] = useState([]);
  const [komponens, setKomponens] = useState([]);
  const [missionData, setMissionData] = useState(null);
  const [showSuccessAdd, setShowSuccessAdd] = useState(false);
  const [showSuccessAddFile, setShowSuccessAddFile] = useState(false);
  const [showModalFile, setShowModalFile] = useState(false);
  const [deleteFileUuid, setDeleteFileUuid] = useState(null);
  const [showConfirmDeleteFile, setShowConfirmDeleteFile] = useState(false);
  const [showSuccessDelete, setShowSuccessDelete] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});
  const [openIndex, setOpenIndex] = useState(null);
  const [downloadFileUuid, setDownloadFileUuid] = useState(null);
  const [showConfirmDownload, setShowConfirmDownload] = useState(false);
  const [showSuccessSave, setShowSuccessSave] = useState(false);
  const [gpsEnabled, setGpsEnabled] = useState(false);

  useEffect(() => {
    const fetchFlightData = async () => {
      try {
        const data = await penerbanganMisiService.getFlightById(uuid);
        setFlightData(data);
        setCheckedItems({
          "Before Start": {
            "Wing Joint (Locked)": data.before_start,
            "Tail Joint (Locked)": data.before_start,
            "Servo Motor (Secure)": data.before_start,
            "Servo Linkages (Checked)": data.before_start,
            "Brushless Motor (Secure)": data.before_start,
            "Propeller (Secure)": data.before_start,
            "Head Door (Open)": data.before_start,
            "Main Battery (Install In Batt Comp)": data.before_start,
            "Velcro Battery Straps (Secure)": data.before_start,
            "Center Of Gravity (Checked)": data.before_start,
          },
          "Push/Start": {
            "Remote Control (On)": data.push_start,
            "Throttle Cutr Switch (On)": data.push_start,
            "Main Battery (Conn)": data.push_start,
            "Flight Control (Start)": data.push_start,
            "Camera (On)": data.push_start,
            "Focus (Set)": data.push_start,
            "Camera Frame (UnDamaged)": data.push_start,
          },
          "After Start": {
            "Mission Planner (Start)": data.after_start,
            "Telem (Conn)": data.after_start,
            "Flight Plan (Set)": data.after_start,
            "Alt_Hold_Rtl (Set)": data.after_start,
            "Rtl_Radius (Set)": data.after_start,
            "Wp_Loiter_Rad (Set)": data.after_start,
            "Preflight Calibration (Set)": data.after_start,
            "Flight Mode (Checked)": data.after_start,
            "RSSI (Above 90%)": data.after_start,
            "GSP Sat Count (Above 10 Satellite)": data.after_start,
            "HDOP (Below 0.8)": data.after_start,
            "EKF (Checked)": data.after_start,
            "VIBE (Checked)": data.after_start,
            "Heading (North)": data.after_start,
          },
          "Before Takeoff": {
            "Flight Plan (Verified)": data.before_takeoff,
            "Head Door (Locked)": data.before_takeoff,
            "Safety Switch (Engaged)": data.before_takeoff,
            "Motor Arming (Armed)": data.before_takeoff,
            "Flight Mode (FBWA)": data.before_takeoff,
            "Actuator Movement (Checked)": data.before_takeoff,
            "Camera Trigger (Trigger Now)": data.before_takeoff,
            "Throttle Cut Switch(Off)": data.before_takeoff,
            "Thrust (Checked)": data.before_takeoff,
          },
          "After Takeoff": {
            "Flight Mode (Auto)": data.after_takeoff,
            "Altitude (Meter)": data.after_takeoff,
            "Ground Speed (m/s)": data.after_takeoff,
            "Heading To WP (Checked)": data.after_takeoff,
            "Flying On The Path (On, Safely)": data.after_takeoff,
            "RSSI (%)": data.after_takeoff,
            "Camera Trigger Icon (Receive)": data.after_takeoff,
          },
          "Before Landing": {
            "RTL (Checked)": data.before_landing,
            "ALT_Hold_RTL (Set)": data.before_landing,
            "Altitude And Ground Speed Report (Set)": data.before_landing,
            "Flight Mode (FBWA)": data.before_landing,
          },
          "After Landing": {
            "Motor Disarm": data.after_landing,
            "Safety Switch (Released)": data.after_landing,
            "Telem (Disconn)": data.after_landing,
            "Camera (Off)": data.after_landing,
            "Battery (Disconn)": data.after_landing,
            "Remote Control (Off)": data.after_landing,
            "Mission Planner (Off)": data.after_landing,
          },
        });
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchFlightData();
  }, [uuid]);

  useEffect(() => {
    const checkGpsStatus = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // GPS diaktifkan
            setGpsEnabled(true);
          },
          (error) => {
            // GPS tidak aktif atau akses ditolak
            setGpsEnabled(false);
          }
        );
      }
    };

    checkGpsStatus();
  }, []);

  useEffect(() => {
    const uuid = sessionStorage.getItem("misiUUID");

    if (!uuid) {
      setError("UUID not found in session storage.");
      setLoading(false);
      return;
    }

    const fetchMissionDetails = async () => {
      try {
        const { mission } = await misiService.getMisiById(uuid);

        const extractUsersByRole = (role) =>
          mission.userMisi
            .filter((user) => user.role === role)
            .map((user) => ({ value: user.uuid, label: user.name }));

        setMissionData(mission);
        setPilots(extractUsersByRole("Pilot"));
        setGcsOperators(extractUsersByRole("GCS Operator"));
        setWahanas(
          mission.wahanas.map(({ uuid, nama_wahana }) => ({
            value: uuid,
            label: nama_wahana,
          }))
        );
        setKomponens(
          mission.komponens.map(({ uuid, nama_komponen }) => ({
            value: uuid,
            label: nama_komponen,
          }))
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMissionDetails();
  }, [uuid]);

  const handleShowModal = () => {
    if (gpsEnabled) {
      setShowModal(true);
    }
  };
  const handleCloseModal = () => setShowModal(false);

  const handleShowModalFile = () => setShowModalFile(true);
  const handleCloseModalFile = () => setShowModalFile(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleStatusChange = (e) => {
    setFormData({
      ...formData,
      status: e.target.value === "true",
    });
  };

  const calculateDuration = (mulai, selesai) => {
    const startTime = new Date(mulai);
    const endTime = new Date(selesai);
    const durasi = (endTime - startTime) / 60000; // Duration in minutes
    return Math.round(durasi); // Rounding to the nearest minute
  };

  const getCurrentLocation = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation
        ? navigator.geolocation.getCurrentPosition(
            ({ coords }) =>
              resolve({
                latitude: coords.latitude,
                longitude: coords.longitude,
              }),
            (error) => reject(error.message)
          )
        : reject("Geolocation is not supported by this browser.");
    });

  const formatDate = (date) =>
    new Date(date).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

  const refreshMissionDetails = async () => {
    try {
      const data = await penerbanganMisiService.getFlightById(uuid);
      setFlightData(data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const location = await getCurrentLocation();

      const dataToSubmit = {
        ...formData,
        mulai: formatDate(formData.mulai),
        selesai: formatDate(formData.selesai),
        durasi: calculateDuration(formData.mulai, formData.selesai),
        ...location,
      };

      console.log("Form Data to Submit:", dataToSubmit);

      const response = await penerbanganMisiService.createFlight(
        uuid,
        dataToSubmit
      );
      console.log(response.msg, response);

      setShowSuccessAdd(true);
      await refreshMissionDetails();
    } catch (err) {
      setError(`Error creating flight: ${err}`);
      console.error("Error creating flight:", err);
    } finally {
      handleCloseModal();
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    }
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();

    const dataToSubmit = new FormData();
    dataToSubmit.append("notice_to_airman", formData.notice_to_airman);
    dataToSubmit.append(
      "flight_security_clearense",
      formData.flight_security_clearense
    );
    dataToSubmit.append("izin_lokasi_terbang", formData.izin_lokasi_terbang);
    dataToSubmit.append("flight_plan", formData.flight_plan);

    try {
      const response = await penerbanganMisiService.uploadFlightFile(
        uuid,
        dataToSubmit
      );
      console.log(response.msg, response);
      setShowSuccessAddFile(true);
      handleCloseModalFile();
      await refreshMissionDetails();
    } catch (error) {
      console.error("Upload error:", error);
      alert("An error occurred while uploading files.");
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!flightData) return <p>No flight data available.</p>;
  if (!missionData) return <p>No Mission data available.</p>;

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { berkas } = flightData;

  // Categorize files
  const fileCategories = {
    notice_to_airman: berkas.filter((file) => file.tipe === "notice_to_airman"),
    izin_lokasi_terbang: berkas.filter(
      (file) => file.tipe === "izin_lokasi_terbang"
    ),
    flight_security_clearense: berkas.filter(
      (file) => file.tipe === "flight_security_clearense"
    ),
    flight_plan: berkas.filter((file) => file.tipe === "flight_plan"),
  };

  const handleDeleteFileBerkas = (uuid) => {
    setDeleteFileUuid(uuid);
    setShowConfirmDeleteFile(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await penerbanganMisiService.deleteFlightFile(deleteFileUuid); // Call API to delete the photo
      await refreshMissionDetails(); // Refresh mission details after deletion
      setShowConfirmDeleteFile(false);
      setShowSuccessDelete(true);
    } catch (error) {
      console.error("Error deleting documentation:", error);
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmDeleteFile(false);
  };

  const handleDownload = (uuid) => {
    setDownloadFileUuid(uuid);
    setShowConfirmDownload(true);
  };

  const handleConfirmDownload = async () => {
    try {
      const file = berkas.find((file) => file.uuid === downloadFileUuid);
      if (file && file.berkas_urls) {
        // Mendapatkan data file dari URL
        const response = await fetch(file.berkas_urls);

        if (response.ok) {
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `file_${downloadFileUuid}`; // Nama file dapat disesuaikan
          link.click();
          // Cleanup
          window.URL.revokeObjectURL(link.href);
        } else {
          throw new Error("Gagal mengunduh file.");
        }
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setShowConfirmDownload(false);
    }
  };

  const checklistData = {
    "Before Start": [
      "Wing Joint (Locked)",
      "Tail Joint (Locked)",
      "Servo Motor (Secure)",
      "Servo Linkages (Checked)",
      "Brushless Motor (Secure)",
      "Propeller (Secure)",
      "Head Door (Open)",
      "Main Battery (Install In Batt Comp)",
      "Velcro Battery Straps (Secure)",
      "Center Of Gravity (Checked)",
    ],
    "Push/Start": [
      "Remote Control (On)",
      "Throttle Cutr Switch (On)",
      "Main Battery (Conn)",
      "Flight Control (Start)",
      "Camera (On)",
      "Focus (Set)",
      "Camera Frame (UnDamaged)",
    ],
    "After Start": [
      "Mission Planner (Start)",
      "Telem (Conn)",
      "Flight Plan (Set)",
      "Alt_Hold_Rtl (Set)",
      "Rtl_Radius (Set)",
      "Wp_Loiter_Rad (Set)",
      "Preflight Calibration (Set)",
      "Flight Mode (Checked)",
      "RSSI (Above 90%)",
      "GSP Sat Count (Above 10 Satellite)",
      "HDOP (Below 0.8)",
      "EKF (Checked)",
      "VIBE (Checked)",
      "Heading (North)",
    ],
    "Before Takeoff": [
      "Flight Plan (Verified)",
      "Head Door (Locked)",
      "Safety Switch (Engaged)",
      "Motor Arming (Armed)",
      "Flight Mode (FBWA)",
      "Actuator Movement (Checked)",
      "Camera Trigger (Trigger Now)",
      "Throttle Cut Switch(Off)",
      "Thrust (Checked)",
    ],
    "After Takeoff": [
      "Flight Mode (Auto)",
      "Altitude (Meter)",
      "Ground Speed (m/s)",
      "Heading To WP (Checked)",
      "Flying On The Path (On, Safely)",
      "RSSI (%)",
      "Camera Trigger Icon (Receive)",
    ],
    "Before Landing": [
      "RTL (Checked)",
      "ALT_Hold_RTL (Set)",
      "Altitude And Ground Speed Report (Set)",
      "Flight Mode (FBWA)",
    ],
    "After Landing": [
      "Motor Disarm",
      "Safety Switch (Released)",
      "Telem (Disconn)",
      "Camera (Off)",
      "Battery (Disconn)",
      "Remote Control (Off)",
      "Mission Planner (Off)",
    ],
  };

  const handleCheckboxChange = (section, item) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [section]: {
        ...prevCheckedItems[section],
        [item]: !prevCheckedItems[section]?.[item],
      },
    }));
  };

  const handleAccordionClick = (index) => {
    // Toggle the accordion open or closed
    setOpenIndex(openIndex === index ? null : index);
  };

  const allChecked = (section) => {
    const items = checklistData[section];
    const checked = checkedItems[section] || {};
    return items.every((item) => checked[item] === true);
  };

  const handleSave = async (section) => {
    const checklistMap = {
      "Before Start": "before_start",
      "Push/Start": "push_start",
      "After Start": "after_start",
      "Before Takeoff": "before_takeoff",
      "After Takeoff": "after_takeoff",
      "Before Landing": "before_landing",
      "After Landing": "after_landing",
    };

    const listChecklist = checklistMap[section];

    if (listChecklist) {
      try {
        await penerbanganMisiService.updateChecklist(uuid, listChecklist);
        setShowSuccessSave(true);
      } catch (error) {
        alert(`Failed to save checklist for ${section}.`);
      }
    }
  };

  const AccessFlightButton = () => {
    const role = localStorage.getItem("Role");
    const allowedRoles = ["Mission Manager", "Pilot", "GCS Operator"];
    return allowedRoles.includes(role);
  };

  const allFilesUploaded = () => {
    // Logika untuk mengecek apakah semua berkas sudah diupload
    return Object.values(fileCategories).every((files) => files.length > 0);
  };

  const allChecklistsChecked = () => {
    // Logika untuk mengecek apakah semua checklist sudah dicentang
    return Object.keys(checklistData).every((section) => allChecked(section));
  };

  return (
    <div className="ml-cl0 mr-cr0">
      <h3 className="text-3xl text-new-300 pt-10">Detail Penerbangan</h3>
      <div className="mt-6 bg-white p-6 rounded-lg shadow-lg w-full overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaClock className="mr-2" /> Waktu Mulai
            </span>
            <span className="text-gray-900 text-xl">{flightData.mulai}</span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaRegClock className="mr-2" /> Waktu Selesai
            </span>
            <span className="text-gray-900 text-xl">{flightData.selesai}</span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaClock className="mr-2 text-blue-900" /> Durasi
            </span>
            <span className="text-gray-900 text-xl">
              {flightData.durasi} menit
            </span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              {flightData.status_misi ? (
                <FaCheckCircle className="mr-2 text-green-600" />
              ) : (
                <FaHourglassHalf className="mr-2 text-orange-600" />
              )}
              Status Misi
            </span>
            <span className="text-gray-900 text-xl">
              {flightData.status_misi ? "Selesai" : "Proses"}
            </span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              {flightData.status_penerbangan ? (
                <FaCheckCircle className="mr-2 text-green-600" />
              ) : (
                <FaHourglassHalf className="mr-2 text-orange-600" />
              )}
              Status Penerbangan
            </span>
            <span className="text-gray-900 text-xl">
              {flightData.status_penerbangan ? "Selesai" : "Proses"}
            </span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaUserTie className="mr-2" /> Pilot
            </span>
            <span className="text-gray-900 text-xl">{flightData.pilot}</span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaUserCog className="mr-2" /> GCS Operator
            </span>
            <span className="text-gray-900 text-xl">{flightData.gcs}</span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaRocket className="mr-2" /> Wahana
            </span>
            <span className="text-gray-900 text-xl">{flightData.wahana}</span>
          </div>

          <div className="flex flex-col items-start p-4 border-l-4 border-blue-700">
            <span className="flex items-center font-bold text-blue-900 text-lg">
              <FaCogs className="mr-2" /> Komponen
            </span>
            <span className="text-gray-900 text-xl">
              {flightData.komponen_penerbangans.map((komponen) => (
                <div key={komponen.id}>{komponen.nama_komponen}</div>
              ))}
            </span>
          </div>
        </div>
      </div>

      {/* Weather Section */}
      <div className="mt-3">
        <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-bold text-blue-900">Cuaca</h4>
          {flightData.cuacas && flightData.cuacas.length > 0 ? (
            <Slider {...sliderSettings} className="mt-5">
              {flightData.cuacas.map((cuaca) => (
                <div key={cuaca.id} className="px-3">
                  <div className="bg-white shadow-2xl rounded-lg p-6 text-center transform hover:scale-105 transition-transform duration-300 ease-out">
                    <h5 className="text-lg font-semibold text-gray-900 mb-3">
                      {cuaca.time}
                    </h5>

                    {/* Wind Speed Icon */}
                    <div className="flex flex-col items-center mb-3">
                      <FaWind className="text-blue-600 text-6xl mb-3" />
                      <p className="text-blue-600 text-3xl font-bold">
                        {cuaca.windspeed} m/s
                      </p>
                    </div>

                    {/* Temperature and Humidity */}
                    <div className="flex justify-around items-center text-gray-600">
                      <div className="flex items-center space-x-2 space-y-0.5">
                        <FaThermometerHalf className="text-blue-400 text-3xl" />
                        <p className="text-xl font-medium">
                          {cuaca.temperature}°C
                        </p>
                      </div>
                      <div className="flex items-center space-x-2 space-y-0.5">
                        <FaTint className="text-blue-400 text-3xl" />
                        <p className="text-xl font-medium">{cuaca.humidity}%</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="mt-5 bg-white shadow-lg rounded-lg p-6 text-center">
              <h5 className="text-xl font-semibold text-gray-800">
                Penerbangan belum dimulai
              </h5>
              <p className="text-gray-500">
                Cuaca akan ditampilkan setelah mengisi form penerbangan.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Files Section */}
      <div className="mt-3">
        <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-bold text-blue-900 mb-4">Berkas</h4>
          <div className="flex justify-end mb-4">
            {AccessFlightButton() && (
              <Button
                className="py-2 px-6 bg-blue-600 hover:bg-blue-700 text-white rounded-full shadow-md transition-all duration-200"
                onClick={handleShowModalFile}
              >
                Tambah Berkas
              </Button>
            )}
          </div>

          <div className="space-y-8">
            {Object.entries(fileCategories).map(([category, files]) => (
              <div
                key={category}
                className="bg-gray-50 p-4 rounded-lg shadow-sm"
              >
                <h5 className="text-2xl font-semibold text-gray-800 mb-4">
                  {category.replace(/_/g, " ").toUpperCase()}
                </h5>

                {files.length > 0 ? (
                  <ul className="space-y-3">
                    {files.map((file) => (
                      <li
                        key={file.id}
                        className="flex justify-between items-center p-3 bg-white rounded-lg shadow hover:shadow-md transition-shadow duration-200"
                      >
                        <div className="flex items-center space-x-3">
                          <a
                            href={file.berkas_urls}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="no-underline text-inherit hover:no-underline font-medium transition-colors duration-200"
                          >
                            {file.tipe.replace(/_/g, " ").toUpperCase()}
                          </a>
                        </div>

                        <div className="flex items-center space-x-4">
                          <FaDownload
                            className="text-blue-600 hover:text-blue-800 cursor-pointer h-6 w-6 transition-colors duration-200"
                            onClick={() => handleDownload(file.uuid)}
                          />
                          {AccessFlightButton() && (
                            <FaTrash
                              className="text-red-600 hover:text-red-800 cursor-pointer h-6 w-6 transition-colors duration-200"
                              onClick={() => handleDeleteFileBerkas(file.uuid)}
                            />
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-500 italic">Belum ada file</p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Checklist Section */}
      <div className="mt-3">
        <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
          <h4 className="text-2xl font-bold text-blue-900 mb-4">Checklist</h4>
          {Object.keys(checklistData).map((section, index) => (
            <div
              key={section}
              className="border border-gray-300 mb-4 rounded-lg shadow-md bg-white"
            >
              <div
                className={`flex items-center justify-between px-5 py-3 cursor-pointer transition-colors ${
                  allChecked(section)
                    ? "bg-green-600 text-white"
                    : "bg-gray-100"
                } hover:bg-gray-200`}
                onClick={() => handleAccordionClick(index)}
              >
                <span className="font-semibold text-lg">{section}</span>
                <span
                  className={`transform transition-transform ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                >
                  ▼
                </span>
              </div>
              <div
                className={`transition-max-height duration-300 ease-in-out overflow-hidden ${
                  openIndex === index ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="p-4 flex flex-col space-y-3">
                  {checklistData[section].map((item) => (
                    <label key={item} className="flex items-center">
                      <input
                        type="checkbox"
                        checked={checkedItems[section]?.[item] || false}
                        onChange={() => handleCheckboxChange(section, item)}
                        className="mr-3 h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <span className="text-gray-700">{item}</span>
                    </label>
                  ))}
                  <div className="flex justify-end mt-4">
                    {AccessFlightButton() && (
                      <button
                        className={`px-5 py-2 font-semibold rounded-md transition-colors ${
                          allChecked(section)
                            ? "bg-blue-600 hover:bg-blue-700 text-white"
                            : "bg-gray-300 text-gray-500 cursor-not-allowed"
                        }`}
                        onClick={() => handleSave(section)}
                        disabled={!allChecked(section)}
                      >
                        Simpan
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {flightData.status_penerbangan ? (
        <div className="bg-white p-6 mt-3 mb-3 rounded-lg shadow-lg">
          <h4 className="text-2xl font-bold text-blue-900">
            Form Penerbangan Sudah Diisi
          </h4>
          <p className="text-blue-600">Anda telah mengisi form penerbangan.</p>
        </div>
      ) : (
        <div className="bg-white shadow-lg rounded-lg p-6 mt-5 mb-3">
          <h4 className="text-2xl font-bold text-blue-900">Form Penerbangan</h4>
          <p className="text-gray-600">Isi Form Dibawah</p>

          <div className="mt-3">
            {/* Pesan peringatan jika GPS belum hidup */}
            {!gpsEnabled && (
              <p className="text-red-500 mb-4">
                ⚠️ Harap hidupkan GPS sebelum mengisi form!
              </p>
            )}

            {/* Pesan peringatan ditempatkan di sini */}
            {(!allFilesUploaded() || !allChecklistsChecked()) && (
              <p className="text-red-500 mb-4">
                ⚠️ Pastikan semua berkas dan checklist sudah diisi!
              </p>
            )}

            <div className="flex justify-start">
              {AccessFlightButton() && (
                <Button
                  className={`py-2 px-4 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700 transition duration-200 ease-in-out ${
                    !gpsEnabled ||
                    !allFilesUploaded() ||
                    !allChecklistsChecked()
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  onClick={handleShowModal}
                  disabled={
                    !gpsEnabled ||
                    !allFilesUploaded() ||
                    !allChecklistsChecked()
                  }
                >
                  Tambah
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal show={showModalFile} onHide={handleCloseModalFile}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Berkas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitFile}>
            <Form.Group controlId="formNoticeToAirman">
              <Form.Label>Notice To Airman (PDF)</Form.Label>
              <Form.Control
                type="file"
                name="notice_to_airman"
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Form.Group
              controlId="formFlightSecurityClearance"
              className="mt-3"
            >
              <Form.Label>Flight Security Clearance (PDF)</Form.Label>
              <Form.Control
                type="file"
                name="flight_security_clearense"
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Form.Group controlId="formIzinLokasiTerbang" className="mt-3">
              <Form.Label>Izin Lokasi Terbang (PDF)</Form.Label>
              <Form.Control
                type="file"
                name="izin_lokasi_terbang"
                accept="application/pdf"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Form.Group controlId="formFlightPlan" className="mt-3">
              <Form.Label>Flight Plan (Image)</Form.Label>
              <Form.Control
                type="file"
                name="flight_plan"
                accept="image/*"
                onChange={handleFileChange}
              />
            </Form.Group>

            <Button type="submit" className="mt-3" variant="primary">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal Form */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Penerbangan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formMulai">
              <Form.Label>Waktu Mulai</Form.Label>
              <Form.Control
                type="datetime-local"
                name="mulai"
                value={formData.mulai}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formSelesai" className="mt-3">
              <Form.Label>Waktu Selesai</Form.Label>
              <Form.Control
                type="datetime-local"
                name="selesai"
                value={formData.selesai}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formWahana" className="mt-3">
              <Form.Label>Pilih Wahana</Form.Label>
              <Select
                options={wahanas}
                name="wahanaId"
                value={wahanas.find(
                  (option) => option.value === formData.wahanaId
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    wahanaId: selectedOption ? selectedOption.value : "",
                  })
                }
                placeholder="Pilih Wahana"
                isClearable
              />
            </Form.Group>

            <Form.Group controlId="formPilot" className="mt-3">
              <Form.Label>Pilih Pilot</Form.Label>
              <Select
                options={pilots}
                name="pilotId"
                value={pilots.find(
                  (option) => option.value === formData.pilotId
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    pilotId: selectedOption ? selectedOption.value : "",
                  })
                }
                placeholder="Pilih Pilot"
                isClearable
              />
            </Form.Group>

            <Form.Group controlId="formGcs" className="mt-3">
              <Form.Label>Pilih GCS</Form.Label>
              <Select
                options={gcsOperators}
                name="gcsId"
                value={gcsOperators.find(
                  (option) => option.value === formData.gcsId
                )}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    gcsId: selectedOption ? selectedOption.value : "",
                  })
                }
                placeholder="Pilih GCS"
                isClearable
              />
            </Form.Group>

            <Form.Group controlId="formKomponen" className="mt-3">
              <Form.Label>Pilih Komponen</Form.Label>
              <Select
                options={komponens}
                name="komponenId"
                value={komponens.filter((option) =>
                  formData.komponenId.includes(option.value)
                )}
                onChange={(selectedOptions) =>
                  setFormData({
                    ...formData,
                    komponenId: selectedOptions.map((option) => option.value),
                  })
                }
                placeholder="Pilih Komponen"
                isMulti
              />
            </Form.Group>

            <Form.Group controlId="formStatus" className="mt-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status ? "true" : "false"}
                onChange={handleStatusChange}
              >
                <option value="false">Proses</option>
                <option value="true">Selesai</option>
              </Form.Control>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {ReactDOM.createPortal(
        showConfirmDownload && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-semibold mb-4">
                Konfirmasi Download
              </h2>
              <p className="mb-4">Apakah Anda ingin download file ini?</p>
              <div className="flex justify-end gap-4">
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                  onClick={() => setShowConfirmDownload(false)}
                >
                  Batal
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  onClick={handleConfirmDownload}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
        ),
        document.body
      )}

      {ReactDOM.createPortal(
        showConfirmDeleteFile && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96">
              <h2 className="text-lg font-semibold mb-4">
                Konfirmasi Hapus Berkas
              </h2>
              <p className="mb-4">
                Apakah Anda yakin ingin menghapus foto ini?
              </p>
              <div className="flex justify-end gap-4">
                <button
                  className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
                  onClick={handleConfirmClose}
                >
                  Batal
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                  onClick={handleConfirmDelete}
                >
                  Hapus
                </button>
              </div>
            </div>
          </div>
        ),
        document.body
      )}

      {ReactDOM.createPortal(
        showSuccessDelete && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
              <FaCheckCircle className="text-green-500 text-6xl absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2" />{" "}
              {/* Ikon besar di tengah atas */}
              <div className="mt-12 text-center">
                <h2 className="text-xl font-bold mb-2">Sukses</h2>
                <p className="text-gray-600 mb-6">
                  Berkas telah berhasil dihapus.
                </p>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300"
                  onClick={() => setShowSuccessDelete(false)}
                >
                  Oke
                </button>
              </div>
            </div>
          </div>
        ),
        document.body
      )}

      {ReactDOM.createPortal(
        showSuccessAddFile && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
              <FaCheckCircle className="text-green-500 text-6xl absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2" />{" "}
              {/* Ikon besar di tengah atas */}
              <div className="mt-12 text-center">
                <h2 className="text-xl font-bold mb-2">Sukses</h2>
                <p className="text-gray-600 mb-6">
                  Berkas telah berhasil ditambahkan.
                </p>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300"
                  onClick={() => setShowSuccessAddFile(false)}
                >
                  Oke
                </button>
              </div>
            </div>
          </div>
        ),
        document.body
      )}

      {ReactDOM.createPortal(
        showSuccessAdd && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
              <FaCheckCircle className="text-green-500 text-6xl absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2" />
              <div className="mt-12 text-center">
                <h2 className="text-xl font-bold mb-2">Sukses</h2>
                <p className="text-gray-600 mb-6">
                  Data penerbangan telah berhasil ditambahkan.
                </p>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300"
                  onClick={() => setShowSuccessAdd(false)}
                >
                  Oke
                </button>
              </div>
            </div>
          </div>
        ),
        document.body
      )}

      {ReactDOM.createPortal(
        showSuccessSave && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
              <FaCheckCircle className="text-green-500 text-6xl absolute top-[-2.5rem] left-1/2 transform -translate-x-1/2 bg-white rounded-full p-2" />
              <div className="mt-12 text-center">
                <h2 className="text-xl font-bold mb-2">Sukses</h2>
                <p className="text-gray-600 mb-6">
                  Checklist berhasil disimpan.
                </p>
              </div>
              <div className="flex justify-center">
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded-full hover:bg-blue-600 transition-colors duration-300"
                  onClick={() => setShowSuccessSave(false)}
                >
                  Oke
                </button>
              </div>
            </div>
          </div>
        ),
        document.body
      )}
    </div>
  );
}

export default PenerbanganMisi;
