import Dashboard from "../components/Dashboard";

const DashboardPage = () => {
    return (
        <div>
            <Dashboard/>
        </div>
    )
}

export default DashboardPage;